<template>
  <div>
    <el-button
      size="small"
      type="primary"
      icon="el-icon-download"
      :disabled="disabled"
      @click="visible = true"
    >
      {{ showText ? 'Export' : '' }}
    </el-button>
    <export-excel-popup
      v-if="visible"
      :visible="visible"
      :order="order"
      :total="total"
      :columns="columns"
      :detail="detail"
      :type="type"
      :titles="titles"
      :active-tab="activeTab"
      :children="children"
      @close="visible = false"
    />
  </div>
</template>

<script>
import ExportExcelPopup from '../popups/ExportExcel.vue'

export default {
  name: 'ExportExcelButton',
  components: {
    ExportExcelPopup
  },
  props: {
    disabled: Boolean,
    order: String,
    total: Number,
    columns: Array,
    type: String,
    titles: Array,
    detail: Object,
    children: Boolean,
    activeTab: [String, Boolean],
    showText: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
</style>
