<template>
  <el-dialog
    class="import-excel-popup"
    :title="`Import dữ liệu`"
    :width="isContinue ? '500px': '1024px'"
    :visible="visible"
    :show-close="!importing"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="$emit('close')"
  >
    <div v-if="!isContinue">
      <h3 class="text-center mt-0">{{ excelTitle }} ({{ excelData.length }})</h3>
      <el-table
        :data="excelData.map((d, index) => ({ ...d, stt: index + 1 }))"
        style="width: 100%"
        height="450"
      >
        <el-table-column
          v-for="column in [
            {
              key: 'stt',
              label: 'STT',
              fixed: 'left'
            },
            ...excelColumns
          ]"
          :key="column.key"
          :prop="column.key"
          :label="column.label"
          :min-width="column.width"
          :fixed="column.fixed"
        >
          <template slot-scope="scope">
            <div class="text-limited text-limited__2" :title="scope.row[column.key]">
              {{ column.locale ? scope.row[column.locale][column.column_key] : scope.row[column.key] }}
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else>
      <el-radio v-model="isCreate" :label="1">
        Thêm mới
      </el-radio>
      <el-radio v-model="isCreate" :label="0">
        Cập nhật
      </el-radio>
      <div v-if="isCreate" class="mt-4" style="word-break: break-word">
        <p>
          Dữ liệu hợp lệ: {{ validData.length }}/{{ excelData.length }}
        </p>
        <p>* Ghi chú: dữ liệu hợp lệ khi bảng dữ liệu có các trường bắt buộc là "Tên", "Danh mục" (nếu có). ("Danh mục", "Đất nước", "Khu vức", "Tags" đã được khởi tạo trên hệ thống nếu có).</p>
        <p>
          Toàn bộ dữ liệu hợp lệ sẽ được khởi tạo và thêm mới vào hệ thống.
        </p>
        Kết quả sau khi thêm mới: {{ createdTotal }} phần tử
      </div>
      <div v-else class="mt-4" style="word-break: break-word">
        <p>
          Dữ liệu hợp lệ: {{ validData.length }}/{{ excelData.length }}
        </p>
        <p>* Ghi chú: dữ liệu hợp lệ khi bảng dữ liệu có các trường bắt buộc là "ID", "Tên", "Danh mục" (nếu có), ("Danh mục", "Đất nước", "Khu vức", "Tags" đã được khởi tạo trên hệ thống nếu có).</p>
        <p>
          Toàn bộ dữ liệu hợp lệ sẽ được đọc và cập nhật theo "ID" vào hệ thống. Sau khi cập nhật số lượng phần tử không thay đổi nhưng dữ liệu sẽ được làm mới.
        </p>
        <p>
          Lưu ý: Nếu không tìm thấy "ID" đã lưu thì hệ thống sẽ bỏ qua.
        </p>
      </div>
      <div v-if="importing" class="mt-4">
        <el-progress
          :percentage="percentage"
          :stroke-width="12"
        ></el-progress>
      </div>
    </div>
    <span slot="footer">
      <el-row type="flex" align="middle" justify="end">
        <el-button
          v-if="!isContinue"
          type="primary"
          @click="() => { isContinue = true }"
        >
          Tiếp tục
        </el-button>
        <el-button
          v-if="isContinue"
          type="info"
          class="mr-2"
          :disabled="importing"
          @click="() => { isContinue = false }"
        >
          Quay lại
        </el-button>
        <el-button
          v-if="isContinue"
          type="primary"
          :loading="importing"
          @click="() => handleImportData()"
        >
          Xác nhận
        </el-button>
      </el-row>
    </span>
  </el-dialog>
</template>

<script>
import dayjs from 'dayjs'
import { mapGetters, mapActions } from 'vuex'
import { convertData } from '@/utils/import'
import { createPlace, updatePlace } from '@/services/place'
import { createCategory, updateCategory } from '@/services/category'
import { createArtifact, updateArtifact } from '@/services/artifact'

export default {
  name: 'ImportExcelPopup',
  components: {},
  props: {
    visible: Boolean,
    columns: Array,
    type: String,
    titles: Array,
    excelData: Array,
    total: Number,
    allTags: Array,
    detail: Object,
    children: Boolean,
    activeTab: [String, Boolean]
  },
  data () {
    return {
      importing: false,
      isContinue: false,
      isCreate: 1,
      imported: 0,
      importSize: 10
    }
  },
  computed: {
    ...mapGetters(['language', 'constant']),
    locales() {
      return this.constant.constants.locales.filter((l) => l.code !== 'vi')
    },
    excelColumns() {
      const languageColumns = this.columns.filter((c) => c.language)
      const lColumns = this.locales.map((l) => {
        return languageColumns.map((column) => ({
          ...column,
          locale: l.code,
          column_key: column.key,
          key: `${column.key}__${l.code}`,
          label: `${column.label} (${l.code})`
        }))
      }).flat()
      return [...this.columns, ...lColumns].map((c) => ({ ...c, label: c.label.toUpperCase(), field: c.key }))
    },
    excelTitle() {
      if (this.type === 'category') {
        return 'Danh mục ' + this.titles.find((t) => t.value === this.activeTab).name
      }
      if (this.type === 'artifact') {
        return 'Danh sách'
      }
      return this.titles.find((t) => t.value === this.activeTab).label
    },
    validData() {
      if (['category', 'artifact'].includes(this.type)) {
        return this.excelData.filter((d) => !!d.name && (this.isCreate ? true : !!d.id))
      }
      return this.excelData.filter((d) => !!d.name && !!d.category_id && (this.isCreate ? true : !!d.id))
    },
    createdTotal() {
      return this.total + (this.validData.length || 0)
    },
    percentage() {
      return Number.parseFloat(this.imported / this.validData.length * 100).toFixed(0)
    },
    payloadData() {
      return this.validData.map((d) => {
        let vi = convertData(d, this.columns, { ...this.constant, tags: this.allTags }, this.type)
        if (this.detail && this.detail.id && !['category', 'artifact', 'comment'].includes(this.type)) {
          if (this.children && this.detail.type === this.type) {
            vi = { ...vi, parent_id: this.detail.id }
          } else {
            vi = { ...vi, [`${this.detail.type}_id`]: this.detail.id }
          }
        }
        const data = { vi }
        this.locales.forEach(locale => {
          data[locale.code] = {
            ...vi,
            ...d[locale.code]
          }
        })
        return data
      })
    }
  },
  methods: {
    ...mapActions('public', ['updateReloadConstant']),
    async handleImportData() {
      this.importing = true
      if (this.isCreate === 1) {
        await this.createData()
      } else {
        await this.updateData()
      }
      setTimeout(() => {
        this.importing = false
        this.$emit('reload')
        this.$emit('close')
        this.$notify.success({
          title: 'Thông báo !',
          message: 'Import dữ liệu thành công !'
        })
      }, 1000)
    },

    async createData() {
      let i = 0
      while (i < this.payloadData.length) {
        const payloads = this.payloadData.slice(i, i + this.importSize)
        const requests = payloads.map((p) => {
          if (this.type === 'category') {
            return this.handleCreateCategory(p)
          }
          if (this.type === 'artifact') {
            return this.handleCreateArtifact(p)
          }
          return this.handleCreatePlace(p)
        })
        await Promise.all(requests)
        i = i + this.importSize
        this.imported = i
      }
    },

    async updateData() {
      let i = 0
      while (i < this.payloadData.length) {
        const payloads = this.payloadData.slice(i, i + this.importSize)
        const requests = payloads.map((p) => {
          if (this.type === 'category') {
            return this.handleUpdateCategory(p)
          }
          if (this.type === 'artifact') {
            return this.handleUpdateArtifact(p)
          }
          return this.handleUpdatePlace(p)
        })
        await Promise.all(requests)
        i = i + this.importSize
        this.imported = i
      }
    },

    async handleCreatePlace(payload) {
      const p = {
        name: payload.vi.name,
        locale: 'vi',
        category_id: payload.vi.category_id,
        place_type: this.type
      }
      try {
        await createPlace(p).then(async (response) => {
          const requests = this.constant.constants.locales.map((l) => {
            try {
              return updatePlace({ locale: l.code, ...payload[l.code], id: response.id })
            } catch (error) {
              return () => {}
            }
          })
          await Promise.all(requests)
        })
      } catch (error) {
        //
      }
    },

    async handleUpdatePlace(payload) {
      try {
        const requests = this.constant.constants.locales.map((l) => {
          try {
            return updatePlace({ locale: l.code, ...payload[l.code] })
          } catch (error) {
            return () => {}
          }
        })
        await Promise.all(requests)
      } catch (error) {
        //
      }
    },

    async handleCreateCategory(payload) {
      const p = {
        name: payload.vi.name,
        locale: 'vi',
        place_type: this.activeTab
      }
      try {
        await createCategory(p, this.activeTab).then(async (response) => {
          const requests = this.constant.constants.locales.map((l) => {
            try {
              return updateCategory({ locale: l.code, ...payload[l.code], id: response.id })
            } catch (error) {
              return () => {}
            }
          })
          await Promise.all(requests)
        })
      } catch (error) {
        //
      }
    },

    async handleUpdateCategory(payload) {
      try {
        const requests = this.constant.constants.locales.map((l) => {
          try {
            return updateCategory({ locale: l.code, ...payload[l.code] })
          } catch (error) {
            return () => {}
          }
        })
        await Promise.all(requests)
      } catch (error) {
        //
      }
    },

    async handleCreateArtifact(payload) {
      const p = {
        name: payload.vi.name,
        locale: 'vi',
        place_id: this.detail.id
      }
      try {
        await createArtifact(p).then(async (response) => {
          const requests = this.constant.constants.locales.map((l) => {
            try {
              return updateArtifact({ locale: l.code, ...payload[l.code], id: response.id, place_id: response.place_id })
            } catch (error) {
              return () => {}
            }
          })
          await Promise.all(requests)
        })
      } catch (error) {
        //
      }
    },

    async handleUpdateArtifact(payload) {
      try {
        const requests = this.constant.constants.locales.map((l) => {
          try {
            return updateArtifact({ locale: l.code, ...payload[l.code], place_id: this.detail.id })
          } catch (error) {
            return () => {}
          }
        })
        await Promise.all(requests)
      } catch (error) {
        //
      }
    },

    handleReloadConstant() {
      this.updateReloadConstant(dayjs().unix())
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
<style lang="scss">
.import-excel-popup {
  .el-dialog__body {
    padding-top: 10px;
  }
}
</style>
